@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-utility-bar {
  background: $color-white;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  display: block;
  overflow: hidden;
  padding: 25px 20px 20px 20px;
  position: relative;
  width: 100%;

  &:before {
    content: '';
    display: block;
    height: 6px;
    background: $color-gold-primary;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .uhc-utility-bar__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }

  details.uhc-utility-bar__inner {
    cursor: pointer;
  }

  .uhc-utility-bar__icon {
    display: block;
    width: 42px;
    height: 42px;
  }

  .uhc-utility-bar__left {
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 0 1 auto;
    position: relative;
  }

  .uhc-utility-bar__right {
    justify-content: flex-end;

    @include media-query {
      flex: 1;
    }
  }

  .uhc-utility-bar__content {
    flex: 1;
  }

  .uhc-utility-bar__chevron {
    display: none;
  }

  .uhc-utility-bar__title {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 5px;
    white-space: nowrap;

    .uhc-utility-bar__title-link {
      color: $color-blue-primary;
      text-decoration: none;
    }

    .uhc-utility-bar__title-icon {
      margin-left: 10px;
      width: 8px;
      height: 12px;
    }
  }

  .uhc-utility-bar__subtitle {
    white-space: pre-wrap;
  }

  .uhc-utility-bar__tags {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    .uhc-utility-tag {
      flex: 1;

      @include media-query(870px) {
        min-width: 250px;
      }
    }
  }

  &.uhc-utility-bar--accordion {
    .uhc-utility-bar__inner {
      flex-direction: column;
      gap: 20px;
    }

    .uhc-utility-bar__right {
      margin-top: 20px;
      width: 100%;
    }

    .uhc-utility-bar__chevron {
      display: block;
    }

    details.uhc-utility-bar__inner[open] {
      .uhc-utility-bar__chevron {
        transform: rotate(180deg);
      }
    }

    .uhc-utility-bar__tags {
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;

      .uhc-utility-tag {
        width: 100%;
      }
    }
  }
}
