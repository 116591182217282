@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-slide-over {
  .uhc-slide-over__panel {
    background: $color-white;
    box-shadow: $box-shadow-base;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;

    @include media-query {
      border-radius: 8px 0 0 8px;
      width: 80vw;
    }

    .uhc-slide-over__content {
      flex: 1;
      overflow-y: auto;
      padding: 20px;

      @include media-query {
        padding: 40px;
      }
    }

    .uhc-slide-over__footer {
      border-top: $border-base;
      box-shadow: $box-shadow-base;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      gap: 10px;
      padding: 20px;

      button {
        min-width: 110px;
        width: 100%;

        @include media-query {
          width: auto;
        }
      }
    }
  }

  &.uhc-slide-over-enter {
    .uhc-overlay {
      opacity: 0;
    }

    .uhc-slide-over__panel {
      transform: translate3d(100%, 0, 0);
    }
  }

  &.uhc-slide-over-enter-active,
  &.uhc-slide-over-enter-done {
    .uhc-overlay {
      opacity: 1;
      transition: opacity 300ms;
    }

    .uhc-slide-over__panel {
      transform: translate3d(0, 0, 0);
      transition: transform ease-out 300ms;
    }
  }

  &.uhc-slide-over-exit {
    .uhc-overlay {
      opacity: 1;
    }
  }

  &.uhc-slide-over-exit-active,
  &.uhc-slide-over-exit-done {
    .uhc-overlay {
      opacity: 0;
      transition: opacity 300ms;
    }

    .uhc-slide-over__panel {
      transform: translate3d(100%, 0, 0);
      transition: transform ease-out 300ms;
    }
  }
}
