@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-plan-card__tophat {
  background: $color-blue-secondary;
  border-radius: 8px 8px 0 0;
  color: $color-white;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  position: relative;
  width: 100%;

  @include media-query {
    width: fit-content;
    max-width: 75%;
    left: 20px;
  }

  a {
    color: $color-white;
    text-decoration: underline;

    &:hover {
      color: $color-white;
    }
  }
}

.uhc-plan-card__wrapper {
  border-top: 20px solid $color-blue-primary;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.uhc-dsnp.uhc-plan-card__wrapper {
  border-top: 20px solid #F5B700;
}

.uhc-plan-card {
  background: $color-white;
  border-radius: 16px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15);

  .uhc-plan-card__header {
    background: $color-blue-primary;
    border-radius: 24px 24px 0 0;
    color: $color-white;
    padding: 20px;
  }

  .uhc-plan-card__main {
    padding: 20px;
    position: relative;
  }

  .uhc-plan-card__footer {
    background: $color-white;
    border-radius: 0 0 8px 8px;
    border-top: $border-base;
    position: relative;
    z-index: 1;

    .uhc-plan-card__footer-inner {
      background: $color-white;
      border-radius: 0 0 8px 8px;
      padding: 20px;
      position: relative;
      z-index: 1;

      @include media-query {
        padding: 20px;
      }
    }
  }

  &.uhc-plan-card--expandable {
    .uhc-plan-card__main {
      height: 160px;
      overflow: hidden;
      padding: 20px 20px 40px;
    }

    .uhc-plan-card__footer {
      &::before {
        border-radius: 10px 10px 0 0;
        background-image: linear-gradient(
          to bottom,
          rgba(168, 168, 168, 0) 12%,
          rgba(159, 159, 159, 0.25) 39%,
          rgba(147, 147, 147, 0.98) 70%,
          #6f6f6f
        );
        content: '';
        display: block;
        height: 60px;
        pointer-events: none;
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        z-index: 0;
      }

      .uhc-plan-card__footer-inner {
        padding: 40px 20px 20px;

        @include media-query {
          padding: 20px;
        }
      }
    }
  }

  &.uhc-plan-card--expandable.uhc-plan-card--expanded {
    .uhc-plan-card__main {
      height: auto;
      overflow: visible;
    }

    .uhc-plan-card__footer {
      height: auto;

      &::before {
        display: none;
      }
    }
  }

  &.uhc-plan-card--personalized {
    border-radius: 0 0 8px 8px;

    .uhc-plan-card__header {
      border-radius: 0;
    }

    @include media-query {
      border-radius: 8px;

      .uhc-plan-card__header {
        border-radius: 8px 8px 0 0;
      }
    }
  }

  .uhc-plan-card__expand-button {
    appearance: none;
    background: $color-white;
    border: $border-base;
    border-radius: 9999px;
    color: $color-blue-secondary;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    top: -21px;
    left: 50%;
    margin-left: -105px;
    height: 42px;
    width: 210px;

    &:focus {
      outline: 3px solid $color-blue-secondary;
    }
  }

  &.uhc-dsnp .uhc-plan-card__main {
    padding-top: 0;
  }
}

.uhc-dsnp__header {
  background: #F5B700;
  display: inline-block;
  padding: 12px 16px;
  border-radius: 8px 8px 20px 20px;
  font-size: 16px;
  font-weight: 700;
  margin-top: -5px;
  margin-bottom: 15px;

  @media (min-width: 600px) {
    margin-bottom: 32px;
  }
}

.uhc-summary-title {
  font-weight: 700;
  margin-bottom: 5px;
}

.uhc-dsnp .uhc-plan-card__main {
  padding-top: 0 !important;
}

.uhc-plan-card__description {
  padding: 16px;
  border-radius: 8px;
  background: #EEF8FB;
  line-height: 22px;
  margin-top: 16px;
}

.uhc-dsnp .uhc-plan-card__description {
  background: #FFFBEB;
}
